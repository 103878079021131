<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="(기간)조치완료 요청일"
            name="actionCompleteRequestDates"
            v-model="searchParam.actionCompleteRequestDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="IBM_TASK_TYPE_CD"
            :rejectItems="['ITT0000145']"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmTaskTypeCd"
            label="업무"
            v-model="searchParam.ibmTaskTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="진행단계"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="IBM_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmClassCd"
            label="개선구분"
            v-model="searchParam.ibmClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :isFlag="true"
            falseLabel="All"
            label="지연된 업무 확인"
            name="overFlag"
            v-model="searchParam.overFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="tasktable"
      title="개선 목록"
      tableId="impr01"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='actionCompleteRequestDate'">
          <span :style="props.row.approvalStatusCd === 'ASC9999999' ? 'text-decoration: line-through' : ''">
            {{props.row.actionCompleteRequestDate}}
            <q-badge v-if="props.row.completeDateOver > 0
              && props.row.ibmStepCd !== 'IS00000001' && props.row.approvalStatusCd !== 'ASC9999999'" color="negative" label="지연" class="blinking" />
          </span>
          <span v-if="props.row.approvalStatusCd === 'ASC9999999'">
            👉 {{props.row.targetDate}}
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionRelactionTaskImpr',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          plantCd: null,
          ibmTaskTypeCd: null,
          actionCompleteRequestDates: [],
          sopConstructionId: '',
        }
      },
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        actionCompleteRequestDates: [],
        ibmTaskTypeCd: null,
        stepCd: null,
        actionFlag: 'D',
        overFlag: 'N',
        ibmClassCd: null,
        sopConstructionId: '',
        constructionFlag: true,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            label: '요청부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '요청정보',
            align: 'left',
            style: 'width:200px',
            type: 'html',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '지연 결재',
            align: 'center',
            sortable: true,
            style: 'width:90px',
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionUserNames',
            field: 'actionUserNames',
            label: '조치자',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      // code setting
      this.$set(this.searchParam, 'sopConstructionId', this.popupParam.sopConstructionId)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
